import { ElNotification } from 'element-plus';
import { logout } from '/@/api/auth';
import { i18n } from '/@/lang';
import { logger } from '/@/utils/logger';
// import { routePush } from '/@/utils/router';

export default function httpErrorStatusHandle(error: any) {
  let message = '';
  if (error && error.response) {
    switch (error.response.status) {
      case 302:
        message = i18n.global.t('axios.Interface redirected!');
        break;
      case 400:
        if (error.response.data.response == 'Incorrect Username or Password') {
          message = i18n.global.t('axios.Incorrect Username or Password!');
        } else {
          message = i18n.global.t('axios.Incorrect parameter!');
        }
        break;
      case 401:
        message = i18n.global.t('axios.The request was not executed, authorization is required');
        setTimeout(() => {
          logout();
        }, 0);
        break;
      case 403:
        if (error.response.data.response === 'Invalid wb token') {
          message = i18n.global.t('axios.invalidWbToken');
        } else {
          message = i18n.global.t('axios.You do not have permission to operate!');
        }
        break;
      case 404:
        message = i18n.global.t('axios.Error requesting address:') + error.response.config.url;
        break;
      case 408:
        message = i18n.global.t('axios.Request timed out!');
        break;
      case 409:
        if (error.response.data === 'Email is already used' || error.response.data.error === 'Email already registred') {
          message = i18n.global.t('axios.Email is already used');
        } else {
          message = i18n.global.t('axios.The same data already exists in the system!');
        }
        break;
      case 500:
        message = i18n.global.t('axios.Server internal error!');
        break;
      case 501:
        message = i18n.global.t('axios.Service not implemented!');
        break;
      case 502:
        message = i18n.global.t('axios.Gateway error!');
        break;
      case 503:
        message = i18n.global.t('axios.Service unavailable!');
        break;
      case 504:
        message = i18n.global.t('axios.The service is temporarily unavailable Please try again later!');
        break;
      case 505:
        message = i18n.global.t('axios.HTTP version is not supported!');
        break;
      default:
        message = i18n.global.t('axios.Abnormal problem, please contact the website administrator!');
        break;
    }
  }
  if (error.message.includes('timeout')) {
    message = i18n.global.t('axios.Network request timeout!');
  }

  if (error.message.includes('Network')) {
    message = window.navigator.onLine ? i18n.global.t('axios.Server exception!') : i18n.global.t('axios.You are disconnected!');

    return true;
  }

  if (error.message.includes('canceled')) {
    message = i18n.global.t('axios.Network request timeout!');
    return true;
  }

  ElNotification({
    type: 'error',
    message,
  });
  logger.error('Axios Error :: ', { error });
  return error;
}
