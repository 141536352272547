import type { IModule } from '/@/types/permission.model';

export const navigationModules: IModule[] = [
  // CRM
  {
    _id: 19,
    sequence: 1,
    mname: 'CRM',
    icon: 'fa fa-line-chart',
    isLink: false,
    to: null,
    parentId: null,
  },
  {
    _id: 24,
    sequence: 2,
    mname: 'Лиды',
    isLink: true,
    to: { name: 'CrmLeads' },
    parentId: 19,
  },
  {
    _id: 25,
    sequence: 3,
    mname: 'Сделки',
    isLink: true,
    to: { name: 'CrmOpportunities' },
    parentId: 19,
  },
  {
    _id: 49,
    sequence: 4,
    mname: 'Контакты',
    isLink: true,
    to: { name: 'CrmContacts' },
    parentId: 19,
  },
  {
    _id: 50,
    sequence: 5,
    mname: 'Компании',
    isLink: true,
    to: { name: 'CrmCompanies' },
    parentId: 19,
  },
  {
    _id: 110,
    sequence: 6,
    mname: 'Задачи',
    isLink: true,
    to: { name: 'CrmTasks' },
    parentId: 19,
  },
  // HR
  {
    _id: 9,
    sequence: 2,
    mname: 'HR',
    icon: 'fa fa-users',
    isLink: false,
    to: null,
    parentId: null,
  },
  {
    _id: 42,
    sequence: 2,
    mname: 'Сотрудники',
    isLink: true,
    to: { name: 'HREmployees' },
    parentId: 9,
  },
  {
    _id: 43,
    sequence: 30,
    mname: 'Кандидаты',
    isLink: true,
    to: { name: 'HRApplications' },
    parentId: 9,
  },
  {
    _id: 14,
    sequence: 3,
    mname: 'Должности',
    isLink: true,
    to: { name: 'HRJobPositions' },
    parentId: 9,
  },
  {
    _id: 15,
    sequence: 4,
    mname: 'Подразделения',
    isLink: true,
    to: { name: 'HRDepartments' },
    parentId: 9,
  },
  //Logistics
  {
    _id: 601,
    sequence: 10,
    mname: 'Логистика',
    icon: 'fa fa-truck',
    isLink: false,
    to: null,
    parentId: null,
  },
  {
    _id: 602,
    sequence: 1,
    mname: 'Планирование поставок',
    isLink: true,
    to: { name: 'SupplyPlanning' },
    parentId: 601,
  },
  {
    _id: 603,
    sequence: 2,
    mname: 'Склады Wildberries',
    isLink: true,
    to: { name: 'WildberriesWarehouses' },
    parentId: 601,
  },
  //Analytics
  {
    _id: 501,
    sequence: 15,
    mname: 'Аналитика',
    isLink: false,
    icon: 'fa fa-pie-chart',
    to: null,
    parentId: null,
  },
  {
    _id: 502,
    sequence: 16,
    mname: 'Статистика продаж',
    isLink: true,
    to: { name: 'AnalyticsSales' },
    parentId: 501,
  },
  //Autobot
  {
    _id: 200,
    sequence: 3,
    mname: 'Автобот',
    isLink: false,
    icon: 'fa fa-envelope-open',
    to: null,
    parentId: null,
  },
  {
    _id: 201,
    sequence: 2,
    mname: 'Шаблоны ответов',
    isLink: true,
    to: { name: 'AutobotTemplates' },
    parentId: 200,
  },
  {
    _id: 203,
    sequence: 3,
    mname: 'Отзывы WB',
    isLink: true,
    to: { name: 'AutobotReviews' },
    parentId: 200,
  },
  {
    _id: 204,
    sequence: 1,
    mname: 'Статистика',
    isLink: true,
    to: { name: 'AutobotStatistics' },
    parentId: 200,
  },
  //Settings
  {
    _id: 1,
    sequence: 1000,
    mname: 'Настройки',
    isLink: false,
    icon: 'fa fa-gear',
    to: null,
    parentId: null,
  },
  {
    _id: 1002,
    sequence: 1,
    mname: 'Задания',
    isLink: true,
    to: { name: 'SettingsNotifications' },
    parentId: 1,
  },
  {
    _id: 51,
    sequence: 1002,
    mname: 'Настройки доступа',
    isLink: true,
    to: { name: 'SettingsRoles' },
    parentId: 1,
  },
  {
    _id: 7,
    sequence: 1000,
    mname: 'Пользователи',
    isLink: true,
    to: { name: 'SettingsUsers' },
    parentId: 1,
  },
  {
    _id: 44,
    sequence: 48,
    mname: 'Рабочие процессы',
    isLink: true,
    to: { name: 'SettingsWorkflows' },
    parentId: 1,
  },
  {
    _id: 50,
    sequence: 1,
    mname: 'Общие настройки',
    isLink: true,
    to: { name: 'SettingsGeneral' },
    parentId: 1,
  },
  {
    _id: 1001,
    sequence: 5,
    mname: 'WB API',
    isLink: true,
    to: { name: 'SettingsAutobot' },
    parentId: 1,
  },
  // ai generator
  {
    _id: 700,
    sequence: 700,
    mname: 'Маркетплейсы',
    icon: 'fa fa-shopping-cart',
    isLink: false,
    to: null,
    parentId: null,
  },
  {
    _id: 701,
    sequence: 701,
    mname: 'AI генератор отзывов',
    isLink: true,
    to: { name: 'AIGenerator' },
    parentId: 700,
  },
  {
    _id: 702,
    sequence: 702,
    mname: 'AI история генераций',
    isLink: true,
    to: { name: 'AIHistory' },
    parentId: 700,
  },
  // autoanswers
  {
    _id: 205,
    sequence: 4,
    mname: 'Вопросы',
    isLink: true,
    to: { name: 'AutoanswersQuestions' },
    parentId: 200,
  },
  {
    _id: 208,
    sequence: 5,
    mname: 'Категории вопросов',
    isLink: true,
    to: { name: 'AutoanswersCategories' },
    parentId: 200,
  },
  {
    _id: 206,
    sequence: 5,
    mname: 'Шаблоны для вопросов',
    isLink: true,
    to: { name: 'AutoanswersTemplates' },
    parentId: 200,
  },
  {
    _id: 207,
    sequence: 6,
    mname: 'Отзывы OZON',
    isLink: true,
    to: { name: 'OzonReviews' },
    parentId: 200,
  },
];
