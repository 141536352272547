import type {IModule, IPermission} from '/@/types/permission.model';
import {navigationModules} from '/@/router/navigation-modules';
import {Local} from '/@/utils/storage';
import {isSpecifiedEnvMode} from '/@/utils/vite';
import sequenceSort from '/@/utils/sorting/sequence-sort';
import {useLicensesInfo} from '/@/stores';

function modulesToOneLevel(array: IModule[]) {
  const modules: IModule[] = [];
  array.forEach(module => {
    if (module.subModules) {
      module.subModules.forEach(subModule => {
        subModule.parentId = module._id;
        modules.push(subModule);
      });
      delete module.subModules;
      modules.push(module);
    }
  });
  return modules;
}
function compareNavigationModules() {
  if (Local.has('userModules')) {
    const userModules = Local.get('userModules');
    const comparedModules: IModule[] = [];
    userModules.forEach((userModule: IPermission) => {
      const matched = navigationModules.find((module: IModule) => userModule.module === module._id);
      if (matched) {
        comparedModules.push(matched);
      }
    });
    return comparedModules;
  }
  throw Error('Нет разрешенных модулей');
}

function rewriteDefaultModuleData(defaultModule: IModule, serverModules: IModule[]) {
  try {
    const matched = serverModules.find((serverModule) => serverModule._id === defaultModule._id);
    if (matched) {
      return {
        ...defaultModule,
        icon: matched.context?.icon,
        mname: matched.mname,
        sequence: matched.sequence,
      };
    }
  } catch (error) {
    throw Error(error);
  }
}
function buildNavigationTree(readyModules: IModule[]) {
  const modules: IModule[] = [];
  const subModules: IModule[] = [];
  readyModules.forEach((readyModule) => {
    if ((readyModule).parentId === null) {
      modules.push((readyModule));
    } else {
      subModules.push((readyModule));
    }
  });
  modules.sort(sequenceSort);
  modules.forEach(module => {
    module.subModules = subModules.filter(subModule => module._id === subModule.parentId);
    module.subModules.sort(sequenceSort);
  });
  return modules;
}
export function prepareModulesForNavigation() {
  const {licenses} = useLicensesInfo();
  try {
    const serverModules = Local.get('serverModules') || [];
    const modulesRow = modulesToOneLevel(serverModules);
    let preparedNavigation = compareNavigationModules()
      .map((comparedModule) => rewriteDefaultModuleData(comparedModule, modulesRow))
      .filter((item) => item !== undefined)

    if (licenses.length === 1 && licenses[0]._id === '3') {
      preparedNavigation = preparedNavigation.filter(item => item._id !== 201 && item._id !== 203);
    }

    //  TODO HOTFIX FOR CRM-1183
    const navigationTree = isSpecifiedEnvMode(import.meta.env.VITE_ENV, ['development', 'stage'])
      ? getMockTree()
      : buildNavigationTree(preparedNavigation);

    const firstRoute = null || navigationTree[0]?.subModules[0]?.to;
    return {navigationTree, preparedNavigation, firstRoute};
  } catch (error) {
    throw Error(error);
  }
}

// когда с модулями закончим можно будет убрать

function getMockTree() {
  //   if (Local.has('userModules')) {
  //     const userModules = Local.get('userModules');
  //     const filteredModules: IModule[] = [];
  //     userModules.forEach((userModule: IPermission) => {
  //       const matched = modules.find((module: IModule) => userModule.module === module._id);
  //       if (matched) {
  //         filteredModules.push(matched);
  //       }
  //     });
  //
  //     const modules: IModule[] = [];
  //     const subModules: IModule[] = [];
  //     filteredModules.forEach(module => {
  //       if (module.parentId === null) {
  //         modules.push(module);
  //       } else {
  //         subModules.push(module);
  //       }
  //     });
  //     modules.sort((a, b) => a.sequence > b.sequence ? 1 : -1);
  //     modules.forEach(module => {
  //       module.subModules = subModules.filter(subModule => module._id === subModule.parentId);
  //       module.subModules.sort((a, b) => a.sequence > b.sequence ? 1 : -1);
  //     });
  //     return modules;
  //   }
  //   return [];
  const modules: IModule[] = [];
  const subModules: IModule[] = [];
  navigationModules.forEach(module => {
    if (module.parentId === null) {
      modules.push(module);
    } else {
      subModules.push(module);
    }
  });
  modules.sort((a, b) => a.sequence > b.sequence ? 1 : -1);
  modules.forEach(module => {
    module.subModules = subModules.filter(subModule => module._id === subModule.parentId);
    module.subModules.sort((a, b) => a.sequence > b.sequence ? 1 : -1);
  });
  return modules;
}
