export default {
  'Operation successful': 'Действие выполнено.',
  'Automatic cancellation due to duplicate request:': 'Что-то пошло не так. Пожалуйста, повторите попытку позже.',
  'Interface redirected!': 'Что-то пошло не так. Пожалуйста, повторите попытку позже.',
  'Incorrect parameter!': 'Что-то пошло не так. Пожалуйста, повторите попытку позже.',
  'You do not have permission to operate!': 'У вас недостаточно прав для этого действия.',
  'The request was not executed, authorization is required': 'Требуется авторизация.',
  'Error requesting address:': 'Что-то пошло не так. Пожалуйста, повторите попытку позже.',
  'Request timed out!': 'Превышено время ожидания. Пожалуйста, повторите попытку позже.',
  'The same data already exists in the system!': 'Что-то пошло не так. Пожалуйста, повторите попытку позже.',
  'Server internal error!': 'Что-то пошло не так. Пожалуйста, повторите попытку позже.',
  'Service not implemented!': 'Что-то пошло не так. Пожалуйста, повторите попытку позже.',
  'Gateway error!': 'Что-то пошло не так. Пожалуйста, повторите попытку позже.',
  'Service unavailable!': 'Сервис временно недоступен. Пожалуйста, повторите попытку позже.',
  'The service is temporarily unavailable Please try again later!': 'Сервис временно недоступен. Пожалуйста, повторите попытку позже.',
  'HTTP version is not supported!': 'Что-то пошло не так. Пожалуйста, повторите попытку позже.',
  'Abnormal problem, please contact the website administrator!': 'Для продолжения работы свяжитесь с администратором.',
  'Network request timeout!': 'Что-то пошло не так. Пожалуйста, повторите попытку позже.',
  'Server exception!': 'Что-то пошло не так. Пожалуйста, повторите попытку позже.',
  'You are disconnected!': 'Нет соединения с сервером. Пожалуйста, повторите попытку позже.',
  'Incorrect Username or Password!': 'Введен неверный логин или пароль',
  'Email already used': 'Такой Email уже используется.',
  invalidWbToken: 'Токен WB недействителен.',
  'Such User is not registered': 'Данного пользователя не существует.',
  'Email is already used': 'Указанный e-mail уже используется в системе',
};
